<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text ml-1">
          <PnxLogo />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto login-page"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('registration.welcome') }} Phoenix Native 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('registration.login-msg') }}
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('registration.password') }}</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>{{ $t('registration.forgotpassword') }}?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('registration.password')"
                      autocomplete="username"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t('registration.signin') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('registration.noaccount') }}? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;{{ $t('registration.create') }}</span>
            </b-link>
            <b-navbar-nav class="nav align-items-center mt-2">
              <locale />
            </b-navbar-nav>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip, BNavbarNav,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import PnxLogo from '@/views/ui/Pnx.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    PnxLogo,
    Locale,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BNavbarNav,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const urlParams = Object.fromEntries(urlSearchParams.entries())
    if (urlParams.utm_campaign && urlParams.utm_campaign === 'bonus') {
      localStorage.setItem('bonus', 1)
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          await useJwt.login({
            grant_type: 'password',
            username: this.userEmail,
            password: this.password,
          }).then(response => {
            const { data } = response
            if (!data.error) {
              useJwt.setToken(data.access_token)
              useJwt.setRefreshToken(data.refresh_token)
              this.setUserData(data)
            }
          }).catch(error => {
            // this.$refs.loginForm.setErrors(error.response.data.error)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('login.error.title'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: this.$t(`login.error.${error.response.data.error}`),
              },
            })
          })
        }
      })
    },
    async setUserData(data) {
      const accountResponse = await useJwt.getUser(data.user_id)
      const { account } = accountResponse.data
      if (Object.keys(account).length !== 0) {
        const userData = {
          ...data,
          id: data.user_id,
          avatar: require('@/assets/images/avatars/13-small.png'),
          role: account.is_admin ? 'admin' : 'client',
          is_agency_member: account.is_agency_member,
          is_agency: account.is_agency,
          email: this.userEmail,
          ability: [{
            action: account.is_admin ? 'manage' : 'read',
            resource: 'ACL',
            subject: 'all',
          }],
        }
        this.$store.dispatch('verticalMenu/setAccountId', data.user_id)
        this.$ability.update(userData.ability)
        localStorage.setItem('userData', JSON.stringify(userData))
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.replace(getHomeRouteForLoggedInUser(userData))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Welcome',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                },
              })
            })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
